import { maskitoPhoneOptionsGenerator } from '@maskito/phone';
import metadata from 'libphonenumber-js/metadata.min.json';

const DIGIT = /\d/;
const NO_MASK = /.*/;
export const regNumMask = [
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
];

export const correctionRegNumMask = [
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  'C',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
];

export const unpMask = [
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
];

export const postalIndexMask = [DIGIT, DIGIT, DIGIT, DIGIT, DIGIT, DIGIT];

export const belarusPhoneMaskWithoutSpaces = [
  '+',
  '3',
  '7',
  '5',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
];

export const exportLicenseMask = [
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
];
export const noMask = new RegExp('.*');
export const onlyDigitsMask = new RegExp('^[0-9]+$');

export const phoneMaskFull = /\+[0-9]{1,3} +[0-9]{2,} +[0-9]{4,}/;
export const phoneMaskIntermediate =
  /^\+?[0-9]{0,3}(?: [0-9]{0,})?(?: [0-9]{0,})?$/;

export const phoneMask = maskitoPhoneOptionsGenerator({
  metadata,
  strict: false,
  countryIsoCode: 'BY',
  separator: '',
});

export const specialCharsRegex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;

export const postalIndexRegex = /[A-Z0-9][A-Z0-9 -]{1,8}[AZ0-9]/;
